
import { PermissionType, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import Draggable from 'vuedraggable'
import { getModule } from 'vuex-module-decorators'

import * as sort from '@/helpers/sort'
import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import { DomainModule } from '@/modules/domain/store'
import { EditorModule } from '@/modules/editor/store'
import { FlowModule } from '@/modules/flow/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import OrganizationUpgradeMenu from '@/modules/organization/components/upgrade-menu.vue'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../helpers/analytics'
import FlowContextMenu from './flow-context-menu/index.vue'
import Name from './name.vue'
import ThumbnailMenu from './thumbnail-menu.vue'

export interface IFlowListItem {
  active: boolean
  click?: () => void
  handleId: string
  id: string
  index: number
  modelId: string
  stepCount: number
  title: string
}

@Component({
  components: {
    Draggable,
    FlowContextMenu,
    Name,
    OrganizationUpgradeMenu,
    ThumbnailMenu
  },
  name: 'FlowPickerMenu'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly menuRef!: any
  @Ref() readonly listRef!: any

  @Prop() readonly permission!: PermissionType
  @Prop() readonly menuOpts?: any

  hoverFlowId = ''
  optionsFlowId = ''
  editingFlowId = ''

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentFlowHandleId () {
    return this.$queryValue('flow')
  }

  get flowDeleteHandleId () {
    return this.$queryValue('flow_delete_dialog')
  }

  get organizationUpgradeDialog () {
    return this.$queryValue('organization_upgrade_dialog')
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get flows () {
    return Object.values(this.flowModule.flows)
  }

  get currentFlows () {
    return this.flows.filter(o => o.diagramId === this.currentDiagram?.id).sort(sort.index)
  }

  get currentFlow () {
    return this.currentFlows.find(o => o.handleId === this.currentFlowHandleId)
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)!
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get flowsLimitReached () {
    return this.flows.length >= this.currentOrganizationLimits.flows || this.currentFlows.length >= this.currentOrganizationLimits.flowsPerDiagram
  }

  get items () {
    return this.currentFlows.map((flow): IFlowListItem | null => {
      const diagram = this.diagramModule.diagrams[flow.diagramId]
      if (!diagram) {
        return null
      }

      const diagramModel = this.modelModule.objects[diagram.modelId]
      if (!diagramModel) {
        return null
      }

      return {
        active: !!(
          flow.diagramId === this.currentDiagram?.id &&
            flow.id === this.currentFlow?.id &&
            this.$routeName?.endsWith('-diagram')
        ),
        click: async () => {
          if (!this.editingFlowId) {
            this.$router.push({
              name: this.currentShareLink ? 'share-diagram' : this.currentVersionId === 'latest' ? 'editor-diagram' : 'version-diagram',
              params: this.$params,
              query: this.$setQuery({
                comment: undefined,
                connection: undefined,
                diagram: diagram.handleId,
                flow: flow.handleId,
                flow_path: undefined,
                flow_step: undefined,
                model: diagramModel.handleId,
                object: undefined,
                x1: undefined,
                x2: undefined,
                y1: undefined,
                y2: undefined
              })
            })
            this.close()
          }
        },
        handleId: flow.handleId,
        id: flow.id,
        index: flow.index,
        modelId: diagram.modelId,
        stepCount: Object.values(flow.steps).filter(o => !o.type?.endsWith('-path')).length,
        title: flow.name
      }
    })
      .filter((o): o is IFlowListItem => !!o)
  }

  get itemCount () {
    return this.items.length
  }

  @Watch('itemCount')
  onFlowCountChanged () {
    this.menuRef?.updateDimensions()
  }

  opened () {
    analytics.flowPickerMenu.track(this, {
      landscapeId: [this.currentLandscapeId],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  close () {
    this.menuRef.isActive = false
  }

  blur () {
    this.optionsFlowId = ''
    this.hoverFlowId = ''
    this.editingFlowId = ''
  }

  loadThumbnails () {
    this.items
      .filter(o => !this.flowModule.flowThumbnails[o.id])
      .forEach(o => {
        this.flowModule.flowThumbnailGet({
          flowId: o.id,
          landscapeId: this.currentLandscapeId,
          versionId: this.currentVersionId
        })
      })
  }

  updateIndex ({ moved }: { moved?: { newIndex: number, oldIndex: number } }) {
    if (moved) {
      const itemsOrdered = [...this.items]
      const element = itemsOrdered[moved.oldIndex]
      itemsOrdered.splice(moved.oldIndex, 1)
      itemsOrdered.splice(Math.max(0, moved.newIndex), 0, element)

      const func: (() => Promise<any>)[] = []
      const tasks: Task[] = []
      const revertTasks: Task[] = []

      itemsOrdered.forEach((o, index) => {
        if (o.index !== index) {
          revertTasks.push({
            id: o.id,
            props: {
              index: o.index
            },
            type: 'flow-update'
          })

          const { flow, flowUpdate } = this.flowModule.generateFlowCommit(o.id, {
            index
          })
          this.flowModule.setFlowVersion(flow)

          func.push(() => this.flowModule.flowUpdate({
            flowId: o.id,
            landscapeId: this.currentLandscape.id,
            props: flowUpdate,
            versionId: this.currentVersion.id
          }))

          tasks.push({
            id: flow.id,
            props: flowUpdate,
            type: 'flow-update'
          })
        }
      })

      this.editorModule.addToTaskQueue({ func })

      this.editorModule.addTaskList({
        revertTasks: [{
          tasks: revertTasks,
          type: 'batch'
        }, {
          route: this.$route,
          type: 'navigation'
        }],
        tasks: [{
          tasks,
          type: 'batch'
        }, {
          route: this.$route,
          type: 'navigation'
        }]
      })
    }
  }
}
