
import { FlowRequired, PermissionType, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import findNextName from '@/helpers/find-next-name'
import * as sort from '@/helpers/sort'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import OrganizationUpgradeMenu from '@/modules/organization/components/upgrade-menu.vue'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import { FlowModule } from '../store'
import PickerMenu from './picker-menu.vue'

@Component({
  components: {
    OrganizationUpgradeMenu,
    PickerMenu
  },
  name: 'FlowPicker'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly permission!: PermissionType
  @Prop({ default: false, type: Boolean }) readonly small!: boolean

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentFlowHandleId () {
    return this.$queryValue('flow')
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get flows () {
    return Object.values(this.flowModule.flows)
  }

  get currentFlows () {
    return this.flows.filter(o => o.diagramId === this.currentDiagram?.id).sort(sort.index)
  }

  get currentFlow () {
    return this.currentFlows.find(o => o.handleId === this.currentFlowHandleId)
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)!
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get nextItemIndex () {
    return Object.values(this.currentFlows).reduce((p, c) => c.index > p ? c.index : p, -1) + 1
  }

  get flowsLimitReached () {
    return this.flows.length >= this.currentOrganizationLimits.flows || this.currentFlows.length >= this.currentOrganizationLimits.flowsPerDiagram
  }

  async createFlow () {
    if (!this.currentDiagram) {
      return
    }

    const flowCreate: FlowRequired = {
      diagramId: this.currentDiagram.id,
      index: this.nextItemIndex,
      name: findNextName('Flow', this.flows),
      steps: {}
    }
    const { flow, flowUpsert } = this.flowModule.generateFlow(this.currentLandscape.id, this.currentVersion.id, flowCreate)
    this.flowModule.setFlow(flow)
    this.editorModule.addToTaskQueue({
      func: () => this.flowModule.flowUpsert({
        flowId: flow.id,
        landscapeId: this.currentLandscape.id,
        props: flowUpsert,
        versionId: this.currentVersion.id
      })
    })

    const revertTasks: Task[] = [{
      id: flow.id,
      type: 'flow-delete'
    }, {
      route: this.$route,
      type: 'navigation'
    }]

    await this.$replaceQuery({
      drawer: 'expanded',
      flow: flow.handleId,
      flow_path: undefined,
      flow_step: undefined
    })

    this.editorModule.addTaskList({
      revertTasks,
      tasks: [{
        id: flow.id,
        props: flowUpsert,
        type: 'flow-create'
      }, {
        route: this.$route,
        type: 'navigation'
      }]
    })
  }
}
